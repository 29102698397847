import React, { useEffect, useState } from "react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { PractitionersApi, ProfileDApi, UsersApi } from '../generated';
import { Practice, Practitioner, Qualification, QualificationNameEnum } from '../generated/profile';
import { User } from '../generated/user';
import { BoxArrowUpRight, CheckLg, PlusLg, Trash } from 'react-bootstrap-icons';
import { Card, Form, Spinner } from 'react-bootstrap';
import swal from '@sweetalert/with-react'

type Expert = {
  id?: string;
  name?: string;
  email?: string;
  office?: string;
  practitioner?: Practitioner;
  practice?: Practice;
  user?: User;
};

function getPractitionersForUser(user: User) {
  if (!user.practitioners || !user.practitioners?.length) return [];
  return user.practitioners.map(async practitionerId => {
    let practitionerRes = await PractitionersApi.practitionerControllerFindOne(practitionerId)
    let practitioner = practitionerRes.data;
    const practice = practitioner.practices[0] || {} as Practice;
    return {
      id: user._id,
      name: `${practitioner.practitionerData.title + " "}${practitioner.practitionerData.firstName} ${practitioner.practitionerData.lastName}`,
      email: user.email,
      office: practice.name,
      practitioner: practitioner,
      practice: practice,
      user: user
    } as Expert;
  })
}

async function getExperts() {
  let usersRes = await UsersApi.usersControllerFindAll()
  let users = usersRes.data;
  console.log("Users:", users);

  const practitioners = await Promise.all(users.map(user => getPractitionersForUser(user)).flat());

  return practitioners
    .sort((a, b) => new Date(b.practitioner!.createdAt).getTime() - new Date(a.practitioner!.createdAt).getTime())
    .map((p, key) => ({...p, key} as Expert));
}

function showExpertDetails(key: number, experts: Object[], setDetailedExpert: Function) {
  let expert = experts[key as number];

  if (expert) {
    setDetailedExpert(expert);
  }
}

function removeQualification(qualification: Qualification, practitioner: Practitioner) {
  return swal({
    title: "Qualifikation löschen",
    message: `Möchtest du die Qualifikation "${qualification.name}" wirklich löschen?`,
    icon: "warning",
    buttons: ["Abbrechen", "Löschen"],
  }).then(async (willDelete) => {
    if (willDelete) {
      let index = practitioner.practitionerData.careerPath.indexOf(qualification);
      practitioner.practitionerData.careerPath.splice(index, 1);
      await PractitionersApi.practitionerControllerUpdate(practitioner._id, { careerPath: practitioner.practitionerData.careerPath });
      return swal("Qualifikation gelöscht", {
        icon: "success",
      });
    }
  });
}

function QualificationCom(props: {qualification: Qualification, practitioner: Practitioner, setExperts: Function}) {
  return (
    <Card>
      <Card.Body>
        <div className="row">
          <div className="col-md-8">
            <p>{props.qualification.name}</p>
            <a href={props.qualification.url} target="_blank" rel="noreferrer">Link <BoxArrowUpRight/></a>
          </div>
          <div className="col-md-4">
            <Trash color="red" size="35px" onClick={() => {
              removeQualification(props.qualification, props.practitioner).then(() => {
                return getExperts();
              }).then((experts) => {
                props.setExperts(experts);
              });
            }}/>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

async function acceptQualifications(expert: Expert, setExperts: Function) {
  let practitioner = expert.practitioner!;
  PractitionersApi.practitionerControllerUpdate(practitioner._id, { qualificationsAccepted: true })
  .then(() => {
    return getExperts();
  }).then((experts) => {
    setExperts(experts);
    return swal("Qualifikationen akzeptiert", "Die Qualifikationen wurden akzeptiert", "success");
  });
}

async function addNewQualification(expert: Expert, setExperts: Function) {
  return swal({
    title: "Neue Qualifikation",
    content: (
      <Form onSubmit={e => {
        e.preventDefault();
        return false;
      }}>
        <Form.Group>
          <Form.Label>Qualifikationstyp:</Form.Label>
          <Form.Select id="newQual_select">
            <option value="DIETICIAN">Dietician</option>
            <option value="DIPLOMA_ECOTROPHOLOGIST">DiplomaEcotrophologist</option>
            <option value="NUTRICIONIST">Nutricionist</option>
            <option value="DIPLOMA_MEDICAL_EDUCATOR">DiplomaMedicalEducator</option>
            <option value="PHYSIOTHERAPIST">Physiotherapist</option>
            <option value="PSYCHOTHERAPIST">Psychotherapist</option>
            <option value="DIETCOOK">Dietcook</option>
            <option value="NUTRICINAL_SCIENTIST">NutricinalScientist</option>
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label>Datei:</Form.Label>
          <Form.Control type="file" size="sm" id="newQual_file" />
        </Form.Group>
      </Form>
    ),
    buttons: ["Abbrechen", "Hinzufügen"],
  }).then(function(willAdd) {
    if (willAdd) {
      let name = (document.querySelector("#newQual_select")! as HTMLSelectElement).value;
      let file = (document.querySelector("#newQual_file")! as HTMLInputElement).files![0];
      uploadFile(file, "profile-pdfs").then((url) => {
        let qualification = {
          name: name as QualificationNameEnum,
          url,
        };
        expert.practitioner!.practitionerData!.careerPath!.push(qualification);
        return PractitionersApi.practitionerControllerUpdate(expert.practitioner!._id, { $set: { 'practitionerData.careerPath': expert.practitioner!.practitionerData!.careerPath! } });
      }).then(() => {
        return getExperts();
      }).then((experts) => {
        setExperts(experts);
      });
    }
  });
}

async function uploadFile(file: File, bucket: string): Promise<string> {
  const formData = new FormData();
  formData.append('file', file);
  if (!file) return "";
  const { data } = await ProfileDApi.appControllerCreatePresignedImageUrl(file.name.split('.').pop() ?? "", bucket);

  const {upload, download} = data;

  await fetch(upload, {
    method: 'PUT',
    body: file
  });

  return download;
}

function removeTabindexFromModal() {
  let modal = document.querySelector('div.modal.show');
  if (modal) {
    modal.removeAttribute('tabindex');
  }
}

export default function Experts() {
  const [experts, setExperts] = useState([] as Expert[]);
  const [detailedExpert, setDetailedExpert] = useState<Expert>({} as Expert);
  const [showExpertDetailModal, setShowExpertDetailModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getExperts().then((expertsP) => {
      // @ts-ignore
      setExperts(expertsP);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    document.querySelectorAll('.Page.Experts tbody tr').forEach((element) => {
      if (element.getAttribute('listener') === 'true') {
        return;
      }

      element.addEventListener('click', (e: Event) => {
        console.log("Clicked on row", e);
        let identifierNode = element.querySelector('td.identifier');

        if (!identifierNode) {
          return;
        }

        console.log("Identifier:", identifierNode.textContent);
        console.log("Expert:", experts[parseInt(identifierNode.textContent as string)]);

        showExpertDetails(Number(identifierNode.textContent), experts, setDetailedExpert);
      });

      element.setAttribute('listener', 'true');
    });
  }, [experts]);

  useEffect(() => {
    if (detailedExpert && Object.keys(detailedExpert).length > 0) {
      setShowExpertDetailModal(true);
    } else {
      setShowExpertDetailModal(false);
    }
  }, [detailedExpert]);

  const columns = [
    {
      dataField: 'key',
      text: 'Nr.',
      classes: 'identifier'
    },
    {
      dataField: 'name',
      text: 'Name',
      classes: 'name'
    }, 
    {
      dataField: 'email',
      text: 'E-Mail',
      classes: 'email'
    }, 
    {
      dataField: 'office',
      text: 'Praxis',
      classes: 'office'
    }
  ];
  
  return <div className="Page Experts">
    <Modal show={showExpertDetailModal} onHide={() => {setDetailedExpert({} as Expert)}} onEntered={() => {removeTabindexFromModal()}} centered>
      <Modal.Header>
        <Modal.Title>{detailedExpert.name ?? ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><strong>Email:</strong> {detailedExpert.user?.email ?? ''}</p>
        <p><strong>Praxis:</strong> {detailedExpert.practice?.name ?? ''}</p>
        <hr/>
        <p><strong>Adresse Praxis:</strong> <br/>
          {detailedExpert.practice?.address?.street ?? ''} {detailedExpert.practice?.address?.houseNumber ?? ''}, <br/>
          {detailedExpert.practice?.address?.country + '-' ?? ''}{detailedExpert.practice?.address?.zipCode ?? ''} {detailedExpert.practice?.address?.city ?? ''} <br/>
          <a href={`https://www.google.com/maps/search/${detailedExpert.practice?.address?.location?.coordinates.join(',')}`} target="_blank" rel="noreferrer">
            Google Maps <BoxArrowUpRight />
          </a>
        </p>
        <p><strong>Telefonnummer Praxis:</strong> {detailedExpert.practice?.mobileNumber ?? ''}</p>
        <p><strong>Website Praxis:</strong> {detailedExpert.practice?.website ?? ''}</p>
        <hr/>
        <p><strong>Qualifikationen:</strong></p>
        {detailedExpert.practitioner?.practitionerData?.careerPath?.map((qualification, index) =>
          <QualificationCom qualification={qualification} practitioner={detailedExpert.practitioner!} key={index} setExperts={setExperts} />)}
        <p className="mt-3"><Button variant="success" onClick={() => addNewQualification(detailedExpert, setExperts)}><PlusLg/> Qualifikation hinzufügen</Button></p>
        <p className="mt-1"><Button variant="primary" onClick={() => acceptQualifications(detailedExpert, setExperts)}><CheckLg/> Qualifikation akzeptieren</Button></p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {setDetailedExpert({} as Expert)}}>
          Schließen
        </Button>
      </Modal.Footer>
    </Modal>

    <div className="container">
      <h3>Experten</h3>
      <BootstrapTable keyField='key' data={ experts } columns={ columns } />
      { isLoading && <div className="d-flex justify-content-center"><Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
      </Spinner></div> }
    </div>
  </div>;
}