/* tslint:disable */
/* eslint-disable */
/**
 * Nibyou Microservice
 * Microservice Description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'houseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {GeoLocation}
     * @memberof Address
     */
    'location': GeoLocation;
}
/**
 * 
 * @export
 * @interface CreatePracticeDto
 */
export interface CreatePracticeDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePracticeDto
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePracticeDto
     */
    'houseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePracticeDto
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePracticeDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePracticeDto
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePracticeDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePracticeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePracticeDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePracticeDto
     */
    'mobileNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePracticeDto
     */
    'website'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePracticeDto
     */
    'logo'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePracticeDto
     */
    'admins'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreatePractitionerDto
 */
export interface CreatePractitionerDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreatePractitionerDto
     */
    'acceptedTerms': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreatePractitionerDto
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePractitionerDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePractitionerDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePractitionerDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePractitionerDto
     */
    'profileImage': string;
    /**
     * 
     * @type {Array<Qualification>}
     * @memberof CreatePractitionerDto
     */
    'careerPath': Array<Qualification>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePractitionerDto
     */
    'practices': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateProfileDto
 */
export interface CreateProfileDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'sex': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'healthInsuranceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'healthInsuranceInstitute': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'houseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'phoneNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProfileDto
     */
    'acceptedTerms': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateProfileDto
     */
    'profileImage': string;
}
/**
 * 
 * @export
 * @interface GeoLocation
 */
export interface GeoLocation {
    /**
     * 
     * @type {Array<number>}
     * @memberof GeoLocation
     */
    'coordinates': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof GeoLocation
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface JsonResponse
 */
export interface JsonResponse {
    /**
     * Data from the API response, can be of any type
     * @type {object}
     * @memberof JsonResponse
     */
    'data'?: object;
    /**
     * Message from the API, likely a status
     * @type {string}
     * @memberof JsonResponse
     */
    'message'?: string;
    /**
     * Error, if thrown
     * @type {object}
     * @memberof JsonResponse
     */
    'error'?: object;
    /**
     * Timestamp of the start of the response
     * @type {string}
     * @memberof JsonResponse
     */
    'ts': string;
}
/**
 * 
 * @export
 * @interface PersonalData
 */
export interface PersonalData {
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'sex': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'healthInsuranceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalData
     */
    'healthInsuranceInstitute': string;
}
/**
 * 
 * @export
 * @interface Practice
 */
export interface Practice {
    /**
     * 
     * @type {Address}
     * @memberof Practice
     */
    'address': Address;
    /**
     * 
     * @type {string}
     * @memberof Practice
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Practice
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Practice
     */
    'mobileNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Practice
     */
    'website'?: string;
    /**
     * 
     * @type {string}
     * @memberof Practice
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Practice
     */
    'logo': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Practice
     */
    'admins'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Practice
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof Practice
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Practice
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface Practitioner
 */
export interface Practitioner {
    /**
     * 
     * @type {boolean}
     * @memberof Practitioner
     */
    'acceptedTerms': boolean;
    /**
     * 
     * @type {PractitionerData}
     * @memberof Practitioner
     */
    'practitionerData': PractitionerData;
    /**
     * 
     * @type {string}
     * @memberof Practitioner
     */
    'status': string;
    /**
     * 
     * @type {Array<Practice>}
     * @memberof Practitioner
     */
    'practices': Array<Practice>;
    /**
     * 
     * @type {string}
     * @memberof Practitioner
     */
    'profileImage': string;
    /**
     * 
     * @type {boolean}
     * @memberof Practitioner
     */
    'qualificationsAccepted': boolean;
    /**
     * 
     * @type {string}
     * @memberof Practitioner
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof Practitioner
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Practitioner
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface PractitionerData
 */
export interface PractitionerData {
    /**
     * 
     * @type {string}
     * @memberof PractitionerData
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof PractitionerData
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PractitionerData
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof PractitionerData
     */
    'lastName': string;
    /**
     * 
     * @type {Array<Qualification>}
     * @memberof PractitionerData
     */
    'careerPath': Array<Qualification>;
}
/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    'acceptedTerms': boolean;
    /**
     * 
     * @type {PersonalData}
     * @memberof Profile
     */
    'personalData': PersonalData;
    /**
     * 
     * @type {Address}
     * @memberof Profile
     */
    'address': Address;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'profileImage': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface Qualification
 */
export interface Qualification {
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    'name': QualificationNameEnum;
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    'url': string;
}

export const QualificationNameEnum = {
    Dietician: 'DIETICIAN',
    DiplomaEcotrophologist: 'DIPLOMA_ECOTROPHOLOGIST',
    Nutricionist: 'NUTRICIONIST',
    DiplomaMedicalEducator: 'DIPLOMA_MEDICAL_EDUCATOR',
    Physiotherapist: 'PHYSIOTHERAPIST',
    Psychotherapist: 'PSYCHOTHERAPIST',
    Dietcook: 'DIETCOOK',
    NutricinalScientist: 'NUTRICINAL_SCIENTIST'
} as const;

export type QualificationNameEnum = typeof QualificationNameEnum[keyof typeof QualificationNameEnum];

/**
 * 
 * @export
 * @interface S3UrlResponse
 */
export interface S3UrlResponse {
    /**
     * 
     * @type {string}
     * @memberof S3UrlResponse
     */
    'upload': string;
    /**
     * 
     * @type {string}
     * @memberof S3UrlResponse
     */
    'download': string;
}
/**
 * 
 * @export
 * @interface UpdatePracticeAddAdminDto
 */
export interface UpdatePracticeAddAdminDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeAddAdminDto
     */
    'admin': string;
}
/**
 * 
 * @export
 * @interface UpdatePracticeAddressDto
 */
export interface UpdatePracticeAddressDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeAddressDto
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeAddressDto
     */
    'houseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeAddressDto
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeAddressDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeAddressDto
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeAddressDto
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface UpdatePracticeDto
 */
export interface UpdatePracticeDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeDto
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeDto
     */
    'houseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeDto
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeDto
     */
    'mobileNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeDto
     */
    'website'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeDto
     */
    'logo'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePracticeDto
     */
    'admins'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdatePracticeEmailDto
 */
export interface UpdatePracticeEmailDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeEmailDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UpdatePracticeLogoDto
 */
export interface UpdatePracticeLogoDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeLogoDto
     */
    'logo': string;
}
/**
 * 
 * @export
 * @interface UpdatePracticeMobileNumberDto
 */
export interface UpdatePracticeMobileNumberDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeMobileNumberDto
     */
    'mobileNumber': string;
}
/**
 * 
 * @export
 * @interface UpdatePracticeNameDto
 */
export interface UpdatePracticeNameDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeNameDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdatePracticeRemoveAdminDto
 */
export interface UpdatePracticeRemoveAdminDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeRemoveAdminDto
     */
    'admin': string;
}
/**
 * 
 * @export
 * @interface UpdatePracticeWebsiteDto
 */
export interface UpdatePracticeWebsiteDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePracticeWebsiteDto
     */
    'website': string;
}
/**
 * 
 * @export
 * @interface UpdatePractitionerDto
 */
export interface UpdatePractitionerDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePractitionerDto
     */
    'acceptedTerms'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdatePractitionerDto
     */
    'salutation'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePractitionerDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePractitionerDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePractitionerDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePractitionerDto
     */
    'profileImage'?: string;
    /**
     * 
     * @type {Array<Qualification>}
     * @memberof UpdatePractitionerDto
     */
    'careerPath'?: Array<Qualification>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePractitionerDto
     */
    'practices'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePractitionerDto
     */
    'qualificationsAccepted'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof UpdatePractitionerDto
     */
    '$inc'?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdatePractitionerDto
     */
    '$min'?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdatePractitionerDto
     */
    '$max'?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdatePractitionerDto
     */
    '$mul'?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdatePractitionerDto
     */
    '$rename'?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdatePractitionerDto
     */
    '$set'?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdatePractitionerDto
     */
    '$setOnInsert'?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdatePractitionerDto
     */
    '$unset'?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdatePractitionerDto
     */
    '$addToSet'?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdatePractitionerDto
     */
    '$pop'?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdatePractitionerDto
     */
    '$pull'?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdatePractitionerDto
     */
    '$pullAll'?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdatePractitionerDto
     */
    '$push'?: object;
}
/**
 * 
 * @export
 * @interface UpdateProfileDto
 */
export interface UpdateProfileDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'salutation'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'sex'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'healthInsuranceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'healthInsuranceInstitute'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'houseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProfileDto
     */
    'acceptedTerms'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'profileImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileDto
     */
    'status': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a presigned URL for the file upload
         * @param {string} ext 
         * @param {string} bucket 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerCreatePresignedImageUrl: async (ext: string, bucket: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ext' is not null or undefined
            assertParamExists('appControllerCreatePresignedImageUrl', 'ext', ext)
            // verify required parameter 'bucket' is not null or undefined
            assertParamExists('appControllerCreatePresignedImageUrl', 'bucket', bucket)
            const localVarPath = `/presigned-s3-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ext !== undefined) {
                localVarQueryParameter['ext'] = ext;
            }

            if (bucket !== undefined) {
                localVarQueryParameter['bucket'] = bucket;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a presigned URL for the file upload
         * @param {string} ext 
         * @param {string} bucket 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerCreatePresignedImageUrl(ext: string, bucket: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3UrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerCreatePresignedImageUrl(ext, bucket, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a presigned URL for the file upload
         * @param {string} ext 
         * @param {string} bucket 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerCreatePresignedImageUrl(ext: string, bucket: string, options?: any): AxiosPromise<S3UrlResponse> {
            return localVarFp.appControllerCreatePresignedImageUrl(ext, bucket, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHealth(options?: any): AxiosPromise<JsonResponse> {
            return localVarFp.appControllerGetHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Get a presigned URL for the file upload
     * @param {string} ext 
     * @param {string} bucket 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerCreatePresignedImageUrl(ext: string, bucket: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerCreatePresignedImageUrl(ext, bucket, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerGetHealth(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerGetHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PracticesApi - axios parameter creator
 * @export
 */
export const PracticesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new Practice
         * @param {CreatePracticeDto} createPracticeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPractice: async (createPracticeDto: CreatePracticeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPracticeDto' is not null or undefined
            assertParamExists('createPractice', 'createPracticeDto', createPracticeDto)
            const localVarPath = `/practice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPracticeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new Admin to the practice
         * @param {string} id 
         * @param {UpdatePracticeAddAdminDto} updatePracticeAddAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerAddAdmin: async (id: string, updatePracticeAddAdminDto: UpdatePracticeAddAdminDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('practiceControllerAddAdmin', 'id', id)
            // verify required parameter 'updatePracticeAddAdminDto' is not null or undefined
            assertParamExists('practiceControllerAddAdmin', 'updatePracticeAddAdminDto', updatePracticeAddAdminDto)
            const localVarPath = `/practice/{id}/admin`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePracticeAddAdminDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all practices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/practice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a practice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('practiceControllerFindOne', 'id', id)
            const localVarPath = `/practice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a practice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('practiceControllerRemove', 'id', id)
            const localVarPath = `/practice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove an Admin from the practice
         * @param {string} id 
         * @param {UpdatePracticeRemoveAdminDto} updatePracticeRemoveAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerRemoveAdmin: async (id: string, updatePracticeRemoveAdminDto: UpdatePracticeRemoveAdminDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('practiceControllerRemoveAdmin', 'id', id)
            // verify required parameter 'updatePracticeRemoveAdminDto' is not null or undefined
            assertParamExists('practiceControllerRemoveAdmin', 'updatePracticeRemoveAdminDto', updatePracticeRemoveAdminDto)
            const localVarPath = `/practice/{id}/admin`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePracticeRemoveAdminDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a practice
         * @param {string} id 
         * @param {UpdatePracticeDto} updatePracticeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerUpdate: async (id: string, updatePracticeDto: UpdatePracticeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('practiceControllerUpdate', 'id', id)
            // verify required parameter 'updatePracticeDto' is not null or undefined
            assertParamExists('practiceControllerUpdate', 'updatePracticeDto', updatePracticeDto)
            const localVarPath = `/practice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePracticeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the practices contact address
         * @param {string} id 
         * @param {UpdatePracticeAddressDto} updatePracticeAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerUpdateAddress: async (id: string, updatePracticeAddressDto: UpdatePracticeAddressDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('practiceControllerUpdateAddress', 'id', id)
            // verify required parameter 'updatePracticeAddressDto' is not null or undefined
            assertParamExists('practiceControllerUpdateAddress', 'updatePracticeAddressDto', updatePracticeAddressDto)
            const localVarPath = `/practice/{id}/address`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePracticeAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the practices contact email
         * @param {string} id 
         * @param {UpdatePracticeEmailDto} updatePracticeEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerUpdateEmail: async (id: string, updatePracticeEmailDto: UpdatePracticeEmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('practiceControllerUpdateEmail', 'id', id)
            // verify required parameter 'updatePracticeEmailDto' is not null or undefined
            assertParamExists('practiceControllerUpdateEmail', 'updatePracticeEmailDto', updatePracticeEmailDto)
            const localVarPath = `/practice/{id}/email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePracticeEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the practices logo
         * @param {string} id 
         * @param {UpdatePracticeLogoDto} updatePracticeLogoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerUpdateLogo: async (id: string, updatePracticeLogoDto: UpdatePracticeLogoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('practiceControllerUpdateLogo', 'id', id)
            // verify required parameter 'updatePracticeLogoDto' is not null or undefined
            assertParamExists('practiceControllerUpdateLogo', 'updatePracticeLogoDto', updatePracticeLogoDto)
            const localVarPath = `/practice/{id}/logo`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePracticeLogoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the practices contact mobile number
         * @param {string} id 
         * @param {UpdatePracticeMobileNumberDto} updatePracticeMobileNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerUpdateMobileNumber: async (id: string, updatePracticeMobileNumberDto: UpdatePracticeMobileNumberDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('practiceControllerUpdateMobileNumber', 'id', id)
            // verify required parameter 'updatePracticeMobileNumberDto' is not null or undefined
            assertParamExists('practiceControllerUpdateMobileNumber', 'updatePracticeMobileNumberDto', updatePracticeMobileNumberDto)
            const localVarPath = `/practice/{id}/mobile-number`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePracticeMobileNumberDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the practices name
         * @param {string} id 
         * @param {UpdatePracticeNameDto} updatePracticeNameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerUpdateName: async (id: string, updatePracticeNameDto: UpdatePracticeNameDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('practiceControllerUpdateName', 'id', id)
            // verify required parameter 'updatePracticeNameDto' is not null or undefined
            assertParamExists('practiceControllerUpdateName', 'updatePracticeNameDto', updatePracticeNameDto)
            const localVarPath = `/practice/{id}/name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePracticeNameDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the practices Website
         * @param {string} id 
         * @param {UpdatePracticeWebsiteDto} updatePracticeWebsiteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerUpdateWebsite: async (id: string, updatePracticeWebsiteDto: UpdatePracticeWebsiteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('practiceControllerUpdateWebsite', 'id', id)
            // verify required parameter 'updatePracticeWebsiteDto' is not null or undefined
            assertParamExists('practiceControllerUpdateWebsite', 'updatePracticeWebsiteDto', updatePracticeWebsiteDto)
            const localVarPath = `/practice/{id}/website`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePracticeWebsiteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PracticesApi - functional programming interface
 * @export
 */
export const PracticesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PracticesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new Practice
         * @param {CreatePracticeDto} createPracticeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPractice(createPracticeDto: CreatePracticeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Practice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPractice(createPracticeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a new Admin to the practice
         * @param {string} id 
         * @param {UpdatePracticeAddAdminDto} updatePracticeAddAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceControllerAddAdmin(id: string, updatePracticeAddAdminDto: UpdatePracticeAddAdminDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Practice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceControllerAddAdmin(id, updatePracticeAddAdminDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all practices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a practice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a practice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove an Admin from the practice
         * @param {string} id 
         * @param {UpdatePracticeRemoveAdminDto} updatePracticeRemoveAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceControllerRemoveAdmin(id: string, updatePracticeRemoveAdminDto: UpdatePracticeRemoveAdminDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Practice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceControllerRemoveAdmin(id, updatePracticeRemoveAdminDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a practice
         * @param {string} id 
         * @param {UpdatePracticeDto} updatePracticeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceControllerUpdate(id: string, updatePracticeDto: UpdatePracticeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceControllerUpdate(id, updatePracticeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the practices contact address
         * @param {string} id 
         * @param {UpdatePracticeAddressDto} updatePracticeAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceControllerUpdateAddress(id: string, updatePracticeAddressDto: UpdatePracticeAddressDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Practice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceControllerUpdateAddress(id, updatePracticeAddressDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the practices contact email
         * @param {string} id 
         * @param {UpdatePracticeEmailDto} updatePracticeEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceControllerUpdateEmail(id: string, updatePracticeEmailDto: UpdatePracticeEmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Practice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceControllerUpdateEmail(id, updatePracticeEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the practices logo
         * @param {string} id 
         * @param {UpdatePracticeLogoDto} updatePracticeLogoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceControllerUpdateLogo(id: string, updatePracticeLogoDto: UpdatePracticeLogoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Practice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceControllerUpdateLogo(id, updatePracticeLogoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the practices contact mobile number
         * @param {string} id 
         * @param {UpdatePracticeMobileNumberDto} updatePracticeMobileNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceControllerUpdateMobileNumber(id: string, updatePracticeMobileNumberDto: UpdatePracticeMobileNumberDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Practice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceControllerUpdateMobileNumber(id, updatePracticeMobileNumberDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the practices name
         * @param {string} id 
         * @param {UpdatePracticeNameDto} updatePracticeNameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceControllerUpdateName(id: string, updatePracticeNameDto: UpdatePracticeNameDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Practice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceControllerUpdateName(id, updatePracticeNameDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the practices Website
         * @param {string} id 
         * @param {UpdatePracticeWebsiteDto} updatePracticeWebsiteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practiceControllerUpdateWebsite(id: string, updatePracticeWebsiteDto: UpdatePracticeWebsiteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Practice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practiceControllerUpdateWebsite(id, updatePracticeWebsiteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PracticesApi - factory interface
 * @export
 */
export const PracticesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PracticesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new Practice
         * @param {CreatePracticeDto} createPracticeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPractice(createPracticeDto: CreatePracticeDto, options?: any): AxiosPromise<Practice> {
            return localVarFp.createPractice(createPracticeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a new Admin to the practice
         * @param {string} id 
         * @param {UpdatePracticeAddAdminDto} updatePracticeAddAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerAddAdmin(id: string, updatePracticeAddAdminDto: UpdatePracticeAddAdminDto, options?: any): AxiosPromise<Practice> {
            return localVarFp.practiceControllerAddAdmin(id, updatePracticeAddAdminDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all practices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerFindAll(options?: any): AxiosPromise<void> {
            return localVarFp.practiceControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a practice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerFindOne(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.practiceControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a practice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.practiceControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove an Admin from the practice
         * @param {string} id 
         * @param {UpdatePracticeRemoveAdminDto} updatePracticeRemoveAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerRemoveAdmin(id: string, updatePracticeRemoveAdminDto: UpdatePracticeRemoveAdminDto, options?: any): AxiosPromise<Practice> {
            return localVarFp.practiceControllerRemoveAdmin(id, updatePracticeRemoveAdminDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a practice
         * @param {string} id 
         * @param {UpdatePracticeDto} updatePracticeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerUpdate(id: string, updatePracticeDto: UpdatePracticeDto, options?: any): AxiosPromise<void> {
            return localVarFp.practiceControllerUpdate(id, updatePracticeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the practices contact address
         * @param {string} id 
         * @param {UpdatePracticeAddressDto} updatePracticeAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerUpdateAddress(id: string, updatePracticeAddressDto: UpdatePracticeAddressDto, options?: any): AxiosPromise<Practice> {
            return localVarFp.practiceControllerUpdateAddress(id, updatePracticeAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the practices contact email
         * @param {string} id 
         * @param {UpdatePracticeEmailDto} updatePracticeEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerUpdateEmail(id: string, updatePracticeEmailDto: UpdatePracticeEmailDto, options?: any): AxiosPromise<Practice> {
            return localVarFp.practiceControllerUpdateEmail(id, updatePracticeEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the practices logo
         * @param {string} id 
         * @param {UpdatePracticeLogoDto} updatePracticeLogoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerUpdateLogo(id: string, updatePracticeLogoDto: UpdatePracticeLogoDto, options?: any): AxiosPromise<Practice> {
            return localVarFp.practiceControllerUpdateLogo(id, updatePracticeLogoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the practices contact mobile number
         * @param {string} id 
         * @param {UpdatePracticeMobileNumberDto} updatePracticeMobileNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerUpdateMobileNumber(id: string, updatePracticeMobileNumberDto: UpdatePracticeMobileNumberDto, options?: any): AxiosPromise<Practice> {
            return localVarFp.practiceControllerUpdateMobileNumber(id, updatePracticeMobileNumberDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the practices name
         * @param {string} id 
         * @param {UpdatePracticeNameDto} updatePracticeNameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerUpdateName(id: string, updatePracticeNameDto: UpdatePracticeNameDto, options?: any): AxiosPromise<Practice> {
            return localVarFp.practiceControllerUpdateName(id, updatePracticeNameDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the practices Website
         * @param {string} id 
         * @param {UpdatePracticeWebsiteDto} updatePracticeWebsiteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practiceControllerUpdateWebsite(id: string, updatePracticeWebsiteDto: UpdatePracticeWebsiteDto, options?: any): AxiosPromise<Practice> {
            return localVarFp.practiceControllerUpdateWebsite(id, updatePracticeWebsiteDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PracticesApi - object-oriented interface
 * @export
 * @class PracticesApi
 * @extends {BaseAPI}
 */
export class PracticesApi extends BaseAPI {
    /**
     * 
     * @summary Create new Practice
     * @param {CreatePracticeDto} createPracticeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticesApi
     */
    public createPractice(createPracticeDto: CreatePracticeDto, options?: AxiosRequestConfig) {
        return PracticesApiFp(this.configuration).createPractice(createPracticeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a new Admin to the practice
     * @param {string} id 
     * @param {UpdatePracticeAddAdminDto} updatePracticeAddAdminDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticesApi
     */
    public practiceControllerAddAdmin(id: string, updatePracticeAddAdminDto: UpdatePracticeAddAdminDto, options?: AxiosRequestConfig) {
        return PracticesApiFp(this.configuration).practiceControllerAddAdmin(id, updatePracticeAddAdminDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all practices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticesApi
     */
    public practiceControllerFindAll(options?: AxiosRequestConfig) {
        return PracticesApiFp(this.configuration).practiceControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a practice
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticesApi
     */
    public practiceControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return PracticesApiFp(this.configuration).practiceControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a practice
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticesApi
     */
    public practiceControllerRemove(id: string, options?: AxiosRequestConfig) {
        return PracticesApiFp(this.configuration).practiceControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove an Admin from the practice
     * @param {string} id 
     * @param {UpdatePracticeRemoveAdminDto} updatePracticeRemoveAdminDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticesApi
     */
    public practiceControllerRemoveAdmin(id: string, updatePracticeRemoveAdminDto: UpdatePracticeRemoveAdminDto, options?: AxiosRequestConfig) {
        return PracticesApiFp(this.configuration).practiceControllerRemoveAdmin(id, updatePracticeRemoveAdminDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a practice
     * @param {string} id 
     * @param {UpdatePracticeDto} updatePracticeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticesApi
     */
    public practiceControllerUpdate(id: string, updatePracticeDto: UpdatePracticeDto, options?: AxiosRequestConfig) {
        return PracticesApiFp(this.configuration).practiceControllerUpdate(id, updatePracticeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the practices contact address
     * @param {string} id 
     * @param {UpdatePracticeAddressDto} updatePracticeAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticesApi
     */
    public practiceControllerUpdateAddress(id: string, updatePracticeAddressDto: UpdatePracticeAddressDto, options?: AxiosRequestConfig) {
        return PracticesApiFp(this.configuration).practiceControllerUpdateAddress(id, updatePracticeAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the practices contact email
     * @param {string} id 
     * @param {UpdatePracticeEmailDto} updatePracticeEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticesApi
     */
    public practiceControllerUpdateEmail(id: string, updatePracticeEmailDto: UpdatePracticeEmailDto, options?: AxiosRequestConfig) {
        return PracticesApiFp(this.configuration).practiceControllerUpdateEmail(id, updatePracticeEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the practices logo
     * @param {string} id 
     * @param {UpdatePracticeLogoDto} updatePracticeLogoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticesApi
     */
    public practiceControllerUpdateLogo(id: string, updatePracticeLogoDto: UpdatePracticeLogoDto, options?: AxiosRequestConfig) {
        return PracticesApiFp(this.configuration).practiceControllerUpdateLogo(id, updatePracticeLogoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the practices contact mobile number
     * @param {string} id 
     * @param {UpdatePracticeMobileNumberDto} updatePracticeMobileNumberDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticesApi
     */
    public practiceControllerUpdateMobileNumber(id: string, updatePracticeMobileNumberDto: UpdatePracticeMobileNumberDto, options?: AxiosRequestConfig) {
        return PracticesApiFp(this.configuration).practiceControllerUpdateMobileNumber(id, updatePracticeMobileNumberDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the practices name
     * @param {string} id 
     * @param {UpdatePracticeNameDto} updatePracticeNameDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticesApi
     */
    public practiceControllerUpdateName(id: string, updatePracticeNameDto: UpdatePracticeNameDto, options?: AxiosRequestConfig) {
        return PracticesApiFp(this.configuration).practiceControllerUpdateName(id, updatePracticeNameDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the practices Website
     * @param {string} id 
     * @param {UpdatePracticeWebsiteDto} updatePracticeWebsiteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticesApi
     */
    public practiceControllerUpdateWebsite(id: string, updatePracticeWebsiteDto: UpdatePracticeWebsiteDto, options?: AxiosRequestConfig) {
        return PracticesApiFp(this.configuration).practiceControllerUpdateWebsite(id, updatePracticeWebsiteDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PractitionersApi - axios parameter creator
 * @export
 */
export const PractitionersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new practitioner
         * @param {CreatePractitionerDto} createPractitionerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practitionerControllerCreate: async (createPractitionerDto: CreatePractitionerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPractitionerDto' is not null or undefined
            assertParamExists('practitionerControllerCreate', 'createPractitionerDto', createPractitionerDto)
            const localVarPath = `/practitioners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPractitionerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all practitioners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practitionerControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/practitioners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all practitioners by practice
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practitionerControllerFindByPractice: async (practiceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            assertParamExists('practitionerControllerFindByPractice', 'practiceId', practiceId)
            const localVarPath = `/practitioners/byPractice/{practiceId}`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a practitioner
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practitionerControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('practitionerControllerFindOne', 'id', id)
            const localVarPath = `/practitioners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a practitioner
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practitionerControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('practitionerControllerRemove', 'id', id)
            const localVarPath = `/practitioners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a practitioner
         * @param {string} id 
         * @param {UpdatePractitionerDto} updatePractitionerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practitionerControllerUpdate: async (id: string, updatePractitionerDto: UpdatePractitionerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('practitionerControllerUpdate', 'id', id)
            // verify required parameter 'updatePractitionerDto' is not null or undefined
            assertParamExists('practitionerControllerUpdate', 'updatePractitionerDto', updatePractitionerDto)
            const localVarPath = `/practitioners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePractitionerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PractitionersApi - functional programming interface
 * @export
 */
export const PractitionersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PractitionersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new practitioner
         * @param {CreatePractitionerDto} createPractitionerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practitionerControllerCreate(createPractitionerDto: CreatePractitionerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Practitioner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practitionerControllerCreate(createPractitionerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all practitioners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practitionerControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Practitioner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practitionerControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all practitioners by practice
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practitionerControllerFindByPractice(practiceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practitionerControllerFindByPractice(practiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a practitioner
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practitionerControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Practitioner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practitionerControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a practitioner
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practitionerControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practitionerControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a practitioner
         * @param {string} id 
         * @param {UpdatePractitionerDto} updatePractitionerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async practitionerControllerUpdate(id: string, updatePractitionerDto: UpdatePractitionerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Practitioner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.practitionerControllerUpdate(id, updatePractitionerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PractitionersApi - factory interface
 * @export
 */
export const PractitionersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PractitionersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new practitioner
         * @param {CreatePractitionerDto} createPractitionerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practitionerControllerCreate(createPractitionerDto: CreatePractitionerDto, options?: any): AxiosPromise<Practitioner> {
            return localVarFp.practitionerControllerCreate(createPractitionerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all practitioners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practitionerControllerFindAll(options?: any): AxiosPromise<Array<Practitioner>> {
            return localVarFp.practitionerControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all practitioners by practice
         * @param {string} practiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practitionerControllerFindByPractice(practiceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.practitionerControllerFindByPractice(practiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a practitioner
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practitionerControllerFindOne(id: string, options?: any): AxiosPromise<Practitioner> {
            return localVarFp.practitionerControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a practitioner
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practitionerControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.practitionerControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a practitioner
         * @param {string} id 
         * @param {UpdatePractitionerDto} updatePractitionerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        practitionerControllerUpdate(id: string, updatePractitionerDto: UpdatePractitionerDto, options?: any): AxiosPromise<Practitioner> {
            return localVarFp.practitionerControllerUpdate(id, updatePractitionerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PractitionersApi - object-oriented interface
 * @export
 * @class PractitionersApi
 * @extends {BaseAPI}
 */
export class PractitionersApi extends BaseAPI {
    /**
     * 
     * @summary Create a new practitioner
     * @param {CreatePractitionerDto} createPractitionerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PractitionersApi
     */
    public practitionerControllerCreate(createPractitionerDto: CreatePractitionerDto, options?: AxiosRequestConfig) {
        return PractitionersApiFp(this.configuration).practitionerControllerCreate(createPractitionerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all practitioners
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PractitionersApi
     */
    public practitionerControllerFindAll(options?: AxiosRequestConfig) {
        return PractitionersApiFp(this.configuration).practitionerControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all practitioners by practice
     * @param {string} practiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PractitionersApi
     */
    public practitionerControllerFindByPractice(practiceId: string, options?: AxiosRequestConfig) {
        return PractitionersApiFp(this.configuration).practitionerControllerFindByPractice(practiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a practitioner
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PractitionersApi
     */
    public practitionerControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return PractitionersApiFp(this.configuration).practitionerControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a practitioner
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PractitionersApi
     */
    public practitionerControllerRemove(id: string, options?: AxiosRequestConfig) {
        return PractitionersApiFp(this.configuration).practitionerControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a practitioner
     * @param {string} id 
     * @param {UpdatePractitionerDto} updatePractitionerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PractitionersApi
     */
    public practitionerControllerUpdate(id: string, updatePractitionerDto: UpdatePractitionerDto, options?: AxiosRequestConfig) {
        return PractitionersApiFp(this.configuration).practitionerControllerUpdate(id, updatePractitionerDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfilesApi - axios parameter creator
 * @export
 */
export const ProfilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new patient profile
         * @param {CreateProfileDto} createProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerCreate: async (createProfileDto: CreateProfileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProfileDto' is not null or undefined
            assertParamExists('profileControllerCreate', 'createProfileDto', createProfileDto)
            const localVarPath = `/profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all patient profiles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a patient profile
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('profileControllerFindOne', 'id', id)
            const localVarPath = `/profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a patient profile
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('profileControllerRemove', 'id', id)
            const localVarPath = `/profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a patient profile
         * @param {string} id 
         * @param {UpdateProfileDto} updateProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerUpdate: async (id: string, updateProfileDto: UpdateProfileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('profileControllerUpdate', 'id', id)
            // verify required parameter 'updateProfileDto' is not null or undefined
            assertParamExists('profileControllerUpdate', 'updateProfileDto', updateProfileDto)
            const localVarPath = `/profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfilesApi - functional programming interface
 * @export
 */
export const ProfilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new patient profile
         * @param {CreateProfileDto} createProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerCreate(createProfileDto: CreateProfileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerCreate(createProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all patient profiles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Profile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a patient profile
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a patient profile
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a patient profile
         * @param {string} id 
         * @param {UpdateProfileDto} updateProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerUpdate(id: string, updateProfileDto: UpdateProfileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerUpdate(id, updateProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfilesApi - factory interface
 * @export
 */
export const ProfilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfilesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new patient profile
         * @param {CreateProfileDto} createProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerCreate(createProfileDto: CreateProfileDto, options?: any): AxiosPromise<Profile> {
            return localVarFp.profileControllerCreate(createProfileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all patient profiles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerFindAll(options?: any): AxiosPromise<Array<Profile>> {
            return localVarFp.profileControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a patient profile
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerFindOne(id: string, options?: any): AxiosPromise<Profile> {
            return localVarFp.profileControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a patient profile
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.profileControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a patient profile
         * @param {string} id 
         * @param {UpdateProfileDto} updateProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerUpdate(id: string, updateProfileDto: UpdateProfileDto, options?: any): AxiosPromise<Profile> {
            return localVarFp.profileControllerUpdate(id, updateProfileDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfilesApi - object-oriented interface
 * @export
 * @class ProfilesApi
 * @extends {BaseAPI}
 */
export class ProfilesApi extends BaseAPI {
    /**
     * 
     * @summary Create a new patient profile
     * @param {CreateProfileDto} createProfileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApi
     */
    public profileControllerCreate(createProfileDto: CreateProfileDto, options?: AxiosRequestConfig) {
        return ProfilesApiFp(this.configuration).profileControllerCreate(createProfileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all patient profiles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApi
     */
    public profileControllerFindAll(options?: AxiosRequestConfig) {
        return ProfilesApiFp(this.configuration).profileControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a patient profile
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApi
     */
    public profileControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return ProfilesApiFp(this.configuration).profileControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a patient profile
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApi
     */
    public profileControllerRemove(id: string, options?: AxiosRequestConfig) {
        return ProfilesApiFp(this.configuration).profileControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a patient profile
     * @param {string} id 
     * @param {UpdateProfileDto} updateProfileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApi
     */
    public profileControllerUpdate(id: string, updateProfileDto: UpdateProfileDto, options?: AxiosRequestConfig) {
        return ProfilesApiFp(this.configuration).profileControllerUpdate(id, updateProfileDto, options).then((request) => request(this.axios, this.basePath));
    }
}


