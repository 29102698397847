import React from "react";
import { PractitionersApi } from '../generated';
import { v4 as uuid } from "uuid";

export default function Dashboard () {
  const [userCount, setUserCount] = React.useState(0);
  const [meetingName, setMeetingName] = React.useState("");
  const [meetingUrl, setMeetingUrl] = React.useState("");

  React.useEffect(() => {
    (async () => {
      let response = await PractitionersApi.practitionerControllerFindAll();
      setUserCount(response.data.length);
    })();
  }, [])

  React.useEffect(() => {
    if (!meetingName) return;
    const id = uuid();
    const name = window.btoa((encodeURIComponent(meetingName)));
    setMeetingUrl(`https://econsult.nibyou.com/join/${name}.${id}`)
  }, [meetingName])

  return (
    <div className="Page Dashboard">
      <div className="container">
        <h3>Dashboard</h3>
        <p>Anzahl Experten: {userCount}</p>
      </div>
      <div className="container">
        <h3>eConsult Links</h3>
        Name des Meetings: <input type={"text"} id="meetingName" value={meetingName} onChange={(e) => setMeetingName(e.target.value)} />
        <p>{ meetingUrl }</p>
      </div>
    </div>
  );
}