import { UsersApi as UsersApiI } from "./user/api";
import { OnboardingTokenApi as OnboardingTokenApiI } from "./user/api";
import { PracticesApi as PracticesApiI } from "./profile/api";
import { PractitionersApi as PractitionersApiI } from "./profile/api";
import { KeyApi } from './pki';
import { DefaultApi as ProfileDefaultApiI } from "./profile/api";
import { ProfilesApi as ProfileApiI } from './profile/api';
import axios, { AxiosInstance } from "axios";
import env from "react-dotenv";
import { parseJwt, refreshAccessWithRefreshToken } from '../helper';

export const axiosInstance: AxiosInstance = axios.create({});

axiosInstance.interceptors.request.use(async function(config) {
  const currentAccessToken = axiosInstance.defaults.headers.common["Authorization"]; // Bearer: <token>
  if (currentAccessToken) {
    let parsedToken = parseJwt((""+currentAccessToken).split(" ")[1]);
    // if token expires in more than one minute, return
    if (parsedToken.exp - Math.floor(Date.now() / 1000) > 60) {
      return config;
    } else {
      await refreshAccessWithRefreshToken();
      return config;
    }
  } else {
    let kcRes = await refreshAccessWithRefreshToken();
    config.headers!.authorization = "Bearer " + kcRes.access_token;
    return config;
  }
});

export const UsersApi = new UsersApiI(undefined, env.USER_SERVICE_URL, axiosInstance);
export const OnboardingTokenApi = new OnboardingTokenApiI(undefined, env.USER_SERVICE_URL, axiosInstance);
export const PracticesApi = new PracticesApiI(undefined, env.PROFILE_SERVICE_URL, axiosInstance);
export const PractitionersApi = new PractitionersApiI(undefined, env.PROFILE_SERVICE_URL, axiosInstance);
export const PKIApi = new KeyApi(undefined, env.PROFILE_SERVICE_URL, axiosInstance);
export const ProfileDApi = new ProfileDefaultApiI(undefined, env.PROFILE_SERVICE_URL, axiosInstance);
export const ProfileApi = new ProfileApiI(undefined, env.PROFILE_SERVICE_URL, axiosInstance);