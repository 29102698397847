import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import LoginPage from './Login';


function ProtectedRoutes() {
  const [isAuthenticated, setIsAuthenticated] = useState((localStorage.refreshToken !== undefined) && (localStorage.refreshToken !== ''));

  //return <Outlet />
  
  if (isAuthenticated) {
    return <Outlet />
  }

  return <LoginPage setIsAuthenticated={setIsAuthenticated} />;
}

export default ProtectedRoutes;