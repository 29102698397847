import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { OnboardingTokenApi } from '../generated';
import { CreateOnboardingTokenDtoAccountTypeEnum } from '../generated/user';
import { OnboardingToken } from '../generated/user';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { Spinner } from 'react-bootstrap';


type OBToken = OnboardingToken & {
  key: number;
}

export default function OnboardingTokens () {
  const defaultDate = new Date();
  defaultDate.setMonth(defaultDate.getMonth() + 1);

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [email, setEmail] = useState('');
  const [validUntil, setValidUntil] = useState(defaultDate.toISOString().split('T')[0]);
  const [accountType, setAccountType] = useState("Practitioner");

  const [onboardingTokens, setOnboardingTokens] = useState([] as OBToken[]);
  const [isLoading, setIsLoading] = useState(true);

  const reloadTokens = async () => {
    let response = await OnboardingTokenApi.onboardingTokenControllerFindAll();
    let tokens = response.data.map((token, index) => ({...token, key: index} as OBToken));
    setOnboardingTokens(tokens.reverse());
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await reloadTokens();
    })();
  }, []);

  function handleDateChange(newDate: string) {
    let date = new Date(newDate);
    console.log(date.toISOString().split('T')[0]);
    setValidUntil(date.toISOString().split('T')[0]);
  }

  function handleSubmit() {
    let date = new Date(validUntil);
    OnboardingTokenApi.onboardingTokenControllerCreate({
      email: email,
      validUntil: date.toISOString(),
      accountType: CreateOnboardingTokenDtoAccountTypeEnum[accountType]
    }).then((response) => {
      console.log(response.data);
      setShowSuccessAlert(true);
      if (response.status === 201) {
        setAlertMsg("Onboarding token created successfully for " + response.data.email);
        setSubmitSuccess(true);
      } else {
        setSubmitSuccess(false);
        setAlertMsg("Failed to create onboarding token. Error: " + JSON.stringify(response.data));
      }
      return reloadTokens().then(() => {
        setEmail('');
        setValidUntil(defaultDate.toISOString().split('T')[0]);
        setAccountType("Practitioner");
      })
    }).catch((error) => {
      setShowSuccessAlert(true);
      setSubmitSuccess(false);
      if( error.response ){
        console.log(error.response.data); // => the response payload
        setAlertMsg("Failed to create onboarding token. Error: " + JSON.stringify(error.response.data?.message));
      } else {
        console.log(error);
        setAlertMsg("Failed to create onboarding token. Unknown error.");
      }
    });
    console.log(email);
    console.log(date.toISOString());
    console.log(accountType);
  }

  const columns = [
    {
      dataField: '_id',
      text: 'Token',
      classes: 'identifier'
    },
    {
      dataField: 'email',
      text: 'Email',
      classes: 'email'
    },
    {
      dataField: 'accountType',
      text: 'Account Type',
      classes: 'accountType'
    },
    {
      dataField: 'validUntil',
      text: 'Valid until',
      classes: 'validUntil'
    }
  ]

  return (
    <div className="Page OnboardingTokens">
      <div className="container">
        <h3>Onboarding Tokens</h3>
        { (showSuccessAlert && submitSuccess) && <Alert key='success' variant='success' onClose={() => setShowSuccessAlert(false)} dismissible>
          {alertMsg}
        </Alert> }
        { (showSuccessAlert && !submitSuccess) && <Alert key='danger' variant='danger' onClose={() => setShowSuccessAlert(false)} dismissible>
          {alertMsg}
        </Alert> }
        
        <div className="col-12 col-lg-6 mt-5">
          <Form onSubmit={(e) => {
            e.preventDefault();
            setSubmitSuccess(false);
            setShowSuccessAlert(false);
            handleSubmit();
          }}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>E-Mail:</Form.Label>
              <Form.Control type="email" value={email} onChange={(e) => {setEmail(e.target.value)}} placeholder="E-Mail" required={true} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="validUntil">
              <Form.Label>Gültig bis:</Form.Label>
              <Form.Control type="date" value={validUntil} onChange={(e) => {handleDateChange(e.target.value)}} required={true} style={{cursor: 'pointer'}}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="accountType">
              <Form.Label>Account-Typ:</Form.Label>
              <Form.Select value={accountType} onChange={(e) => {setAccountType(e.target.value)}} required={true} >
                <option value={"Practitioner"}>Experte</option>
                <option value={"Patient"} disabled={true}>Patient</option>
              </Form.Select>
            </Form.Group>

            <Button variant="primary" type="submit">
              Erstellen
            </Button>
          </Form>
        </div>
        <div className="mt-5"></div>
        <BootstrapTable keyField='key' data={ onboardingTokens } columns={ columns } />
        { isLoading && <div className="d-flex justify-content-center"><Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner></div> }
      </div>
    </div>
  );
}