import React from "react";
import { Outlet, Link, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

function Sidebar() {
  const location = useLocation();

  return <nav className='Sidebar col-2 p-0'>
    <Link className={ location.pathname === '/' ? 'current' : '' } to='/'>Dashboard</Link>
    <Link className={ location.pathname === '/experts' ? 'current' : '' } to='/experts'>Experten</Link>
    <Link className={ location.pathname === '/onboarding-tokens' ? 'current' : '' } to='/onboarding-tokens'>Onboarding-Tokens</Link>
    <Link className={ location.pathname === '/logout' ? 'current' : '' } to='/logout'>Logout</Link>
  </nav>
}

function Layout() {
  
    return <Container fluid={true} className='App h-100'>
      <header className="row">
        <img src="/icons/nibyou.svg" alt="Logo"/>
      </header>
      <div className='row h-100'>
        <Sidebar />
        
        <main className='col-10 p-0'>
            <Outlet />
        </main>
      </div>
    </Container>;
}

export default Layout;