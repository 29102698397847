import React, { useState } from "react";
import { login } from '@nibyou/auth-connector';
import jwt_decode from "jwt-decode";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

type DecodedToken = {
  realmRoles: string
}

function userIsAuthenticated() {
  return !!localStorage.getItem('refreshToken');
}

function userIsAdmin(accessToken: string) {
  try {
    const decodedToken: DecodedToken = jwt_decode(accessToken);

    if (decodedToken === null || typeof decodedToken !== 'object') {
      return false;
    }

    if (decodedToken.realmRoles !== 'admin') {
      return false;
    }

  } catch (invalidTokenError) {
    return false;
  }

  return true;
}

async function handleSubmit(props: {email: string, password: string, setIsAuthenticated?: (isAuthenticated: boolean) => void, setLoginFailed?: (loginFailed: boolean) => void}) {
  const email = props.email;
  const password = props.password;
  const submitBtn: HTMLInputElement|null = document.getElementById('submit') as HTMLInputElement;

  submitBtn!.disabled = false;

  login(email, password).then((response) => {
    console.log(response);
    if (!userIsAuthenticated()) {
      props.setLoginFailed!(true);
      return;
    }

    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken || !userIsAdmin(accessToken)) {
      props.setLoginFailed!(true);
      return;
    }

    if (props.setIsAuthenticated) {
      props.setIsAuthenticated(true);
    } else {
      window.location.href = "/";
    }
  });
}

export default function Login({ setIsAuthenticated }: {setIsAuthenticated?: (isAuthenticated: boolean) => void}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginFailed, setLoginFailed] = useState(false);

  if (userIsAuthenticated()) {
    window.location.href = "/";
  }

  return <div className="Page Login">
    <div className="formWrapper">
      <div className="imageWrapper">
        <img src="/icons/nibyou.svg" alt="Logo"/>
      </div>
      
      <Form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit({
          email: email,
          password: password,
          setIsAuthenticated: setIsAuthenticated,
          setLoginFailed: setLoginFailed
        }).then(r => r);
      }}>
        { loginFailed &&
            <Form.Text className="text-danger">
              Login leider nicht erfolgreich. <br /> Bitte überprüfen Sie Ihre Zugangsdaten.
            </Form.Text>
        }

        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control 
            type="email"
            placeholder="E-Mail"
            name="email"
            defaultValue={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Label>Passwort</Form.Label>
          <Form.Control
            type="password"
            placeholder="Passwort"
            name="password"
            defaultValue={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Group>
        
        <Button variant="primary" type="submit" id="submit">
          Login
        </Button>
      </Form>
    </div>
  </ div>;
}