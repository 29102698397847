import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import ProtectedRoutes from './components/ProtectedRoutes';
import Layout from './components/Layout';
import Login from './components/Login';
import Logout from './components/Logout';
import Dashboard from './components/Dashboard';
import Experts from './components/Experts';
import OnboardingTokens from './components/OnboardingTokens';

function NotFoundPage() {
  return <div className='NotFoundPage'>
    <div>
      <h1>404 Seite nicht gefunden</h1>
      <p>
        Lass dich nicht entmutigen, wahrscheinlich haben wir einen Fehler gemacht. <br />
        Geh am besten zurück zur
      </p>
      <Link to="/">
        Homepage
      </Link>
    </div>
  </div>;
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="*" element={<NotFoundPage />} />

        <Route element={<ProtectedRoutes />}>
          <Route element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/experts" element={<Experts />} />
            <Route path="/onboarding-tokens" element={<OnboardingTokens />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
